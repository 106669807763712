html,
body,
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'DM Sans', sans-serif;
  max-width: 100vw;
}
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Space+Grotesk:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@700&family=Ubuntu:wght@300&display=swap');

/* 
font-family: 'Space Grotesk', sans-serif; 
font-family: 'DM Sans', sans-serif;
font-family: 'Inter', sans-serif;
*/
@font-face {
  font-family: 'Basier Circle Regular';
  font-style: normal;
  font-weight: normal;
  /* src: local("Basier Circle Regular"),
        url("BasierCircle-Regular.woff") format("woff"); */
}

:root {
  --green: #6bb043;
  --blue: #11294c;
  --orange: #f6841f;
  --grey: #e2efda;
  --white: #f4f6f2;
  --darkblack: #212121;
  --lightblack: #333333;
}

/* body{
    width: 100vw;
} */
/* Header Style */

.navbar {
  padding: 18px 60px;
  padding-bottom: 25px;
  background-color: #ffffff;
  /*background: linear-gradient(20.1deg, #70B245 8.53%, #FFFFFF 81.94%);
  opacity: 0.2; 
    transform: matrix(1, 0, 0, 1, 0, 0);*/
  /* display: grid;
  grid-template-columns: 1fr 2fr 127px;
  align-items: center; */
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 2;
  width: 100%;
}
.navigation-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-list {
  list-style-type: none;
  /* padding-left: 4.8rem; */
}

.nav-list .list-item {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
}

.logo {
  width: 186.38px;
  height: 43px;
  margin-top: 14px;
  cursor: pointer;
}

.navbar a {
  text-decoration: none;
  color: #010a44;
}

.navbar a:hover,
.navbar a:focus {
  font-weight: 700;
  border-bottom: 3px solid #6cb044;
}

.current-page {
  font-weight: 700;
  border-bottom: 3px solid #6cb044;
}

.login-btn {
  width: 127px;
  height: 48px;
  /* padding-right: 0; */
  /* margin-right: 100px;   */
  /* padding: 10px 20px; */
  border-radius: 10px;
  background: #010a44;
  border: none;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}

.login-list {
  /* display: block;
    width: 157px;
    height: 78px; */
  /* margin-left:75%;  */
}

.login-list a {
  /* background: #424242;
    /* padding: 1rem; */
  /* list-style: none;
    font-weight: 400;
font-size: 14px;
line-height: 18px;
border-bottom: 0.5px solid;
text-align: center; */
  cursor: pointer;
  /* color: #FFFFFF; */
}

/* .login-btn {
    background-color: #04AA6D;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
  } */

.login-item {
  position: relative;
  display: inline-block;
  /* padding-left: 5rem; */
}

.empty {
  width: 5rem;
}

.login-list {
  display: none;
  position: absolute;
  background-color: #424242;
  margin-top: 10px;
  right: 4px;
  width: 157px;
  /* height: 78px; */
  border-bottom: 2px solid #6cb044;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  z-index: 1;
}

.login-list a {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-family: 'DM Sans';
  height: 39px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

.show {
  display: block;
}

.login-list a:hover {
  background-color: #ada4a5;
}

/* 
.login-item:hover .login-list {
  display: block;
} */

/* .login-item:hover .login-btn {
  background-color: #3e8e41;
} */

header {
  display: none;
}

/* Footer Style */

footer {
  background-color: var(--blue);
  color: #ffffff;
  font-weight: 400;
  display: grid;
  font-size: 16px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    'f1 f2 f3 f4'
    'copy copy copy copy';
  padding: 100px;
  padding-bottom: 0;
  column-gap: 2rem;
  position: sticky;
}

.f1 {
  grid-area: f1;
}

.f2 {
  grid-area: f2;
  /* width: 284px; */
}

.f3 {
  grid-area: f3;
}

.f4 {
  grid-area: f4;
}

.copyright {
  grid-area: copy;
}

.f1 img {
  margin-bottom: 2rem;
  width: 169.04px;
  height: 39px;
}

.f1 p {
  margin-bottom: 2rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.01em;
  padding-right: 2rem;
  /* width: 239px; */
}

footer h5 {
  opacity: 0.4;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 2rem;
  line-height: 26px;
  letter-spacing: -0.01em;
}

footer p {
  /* opacity: 0.8; */
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 2rem;
}

.symbols {
  display: flex;
  gap: 28.5px;
}

footer .sym {
  height: 25px;
  width: 25px;
}
footer .sysm {
  height: 30px;
  width: 30px;
}

.f2 p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.01em;
  /* padding-right: 3rem; */
}

.f2-time {
  display: grid;
  grid-template-columns: auto;
  column-gap: 1rem;
  row-gap: 0;
  color: #ffffff;
  opacity: 0.8;
  /* height: 15px; */
}

.f2-time p {
  margin-bottom: 0.2rem;
  color: #ffffff;
  opacity: 0.8;
}

.f3 p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.01em;
}

.f4 p {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #6cb044;
  opacity: 0.8;
}

.f2 .td {
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

.f3 .contact {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.5em;
  color: #6cb044;
}

.f4 .contact {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.5em;
  grid-template-rows: 2rem 2rem;
}

.copyright {
  border-top: 0.5px solid #ffffff;
  padding: 0;
  margin-top: 4rem;
  padding-top: 25px;

  line-height: 24px;
  color: #f9f9f9;
  letter-spacing: -0.01em;
  opacity: 0.8;
  display: flex;
  justify-content: space-between;
}

.copyright p {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #f9f9f9;
  opacity: 0.8;
}

/* Home Page Style */

.landing1 {
  padding: 40px;
  padding-top: 0;
  padding-right: 0;
  margin-right: 0;
  margin-bottom: 0;
  /* display: grid;
    grid-template-columns: repeat(autofit 1fr);
  gap: 2rem; */
  /* column-gap: 6rem; */

  background: linear-gradient(20.1deg, #70b245 8.53%, #ffffff 81.94%);
  transform: matrix(1, 0, 0, 1, 0, 0);
  /* opacity: 0.2;
     background-blend-mode: multiply; */

  background-image: url(/src/Assets/WebImages/Vector.svg),
    url(/src/Assets/WebImages/Vector2.png);
  background-repeat: no-repeat, no-repeat;
  background-position-y: 50px, 190%;
  background-position-x: 0px, 100px;
  opacity: 0, 0.2;

  display: flex;
  justify-content: space-between;
  /* gap: 94px; */
}

.home-first {
  width: auto;
}

.home-pic {
  display: flex;
  flex-direction: column;
}

.home-pic1 {
  position: relative;
  width: 813px;
  height: 631px;
}

.home-pic2 {
  position: absolute;
  margin-top: 32rem;
}

.home-pic3 {
  position: absolute;
  margin-top: 33rem;
  background-color: #11294c;
  width: 400px;
  height: 180px;
  padding: 30px 40px;
  color: #c8ccd1;
  font-size: 16px;
}

.home-subtitle {
  font-size: 14px;
  text-transform: uppercase;
  color: #010a44;
  margin-top: 7rem;
  margin-bottom: 1rem;
  opacity: 0.75;
}

.home-subtitle1 {
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 13.7833px;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #010a44;
  opacity: 0.65;
}

.home-title {
  font-family: 'Space Grotesk';
  /* font-weight: 700; */
  /* font-size: 64px; */
  /* line-height: 75.52px; */
  /* letter-spacing: -0.02em; */
  color: #333333;
  text-align: left;
  margin-top: 1rem;
  /* width: 433px; */
  /* margin-bottom: -1rem; */
}

.home-sub {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #010a44;
  margin-top: 2rem;
  margin-bottom: 2rem;
  /* width: 354px; */
}

.knowmore-btn {
  width: 175px;
  height: 48px;
  margin-bottom: 5rem;
  background-color: #11294c;
  color: #d9d9d9;
}

.landing2 {
  display: flex;
  justify-content: space-between;
  padding: 100px;
  background: var(--white);
}

.round {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}

.round p {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #010a44;
}

.landing3 {
  background: #ffffff;
  padding: 100px;
}

.home-title1 {
  font-family: 'Space Grotesk';
  font-weight: 700;
  font-size: 60px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #333333;
  margin-top: 1rem;
}

.home-why {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
}

.home-why div {
  align-items: baseline;
}

.home-why h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #010a44;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.home-why img {
  width: 100%;
  border-radius: 5px;
}

.home-why p {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #212121;
  text-align: justify;
}

.landing4 {
  background: #e9efe2;
  /* display: grid;
  grid-template-columns: 1fr 2fr; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 100px;
}

.cap1 {
  position: relative;
}

.cap2 {
  position: absolute;
  margin-left: -9rem;
  margin-top: 1rem;
}

.home-right {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 3rem;
  row-gap: 0;
  margin-left: 2rem;
}

.right-text {
  grid-column: span 2;
  margin-top: -9rem;
}

.right-text p {
  padding-left: 6rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #232226;
  /* 
  font-weight: 700;
  font-size: 14px;
  font-family: 'Space Grotesk';
font-style: normal;
font-weight: 700;
font-size: 60px;
line-height: 100%;
letter-spacing: -0.02em;

color: #333333;
  color: #232226; */
}

.wrapper_left {
  margin-top: 3rem;
  /* width: 30%; */
}

.wrapper_left ul {
  list-style: none;
  /* line-height: 28px; */
}

.wrapper_left p {
  cursor: pointer;
  line-height: 22px;
  padding: 10px;
}

.wrapper_left p:hover {
  color: #6bb043;
  font-weight: 700;
}

.wrapper_left p::after {
  content: '';
  display: block;
  width: 0%;
  height: 2px;
  background-color: #6bb043;
  transition: width 0.3s;
}

.wrapper_left p:hover::after {
  width: 100%;
  transition: width 0.3s;
}

.wrapper_left > ul > li > p {
  padding: 0.6rem;
  padding-right: 0;
  /* width: 200px;
  height: 50px; */
  cursor: pointer;
  text-align: left;
  font-family: 'Space Grotesk';

  font-weight: 500;
  font-size: 16.8px;
  line-height: 22px;
  color: #17161a;
}

.wrapper_left .active p {
  font-weight: 700;
  /* width: 200px; */
  border-right: 4px solid #ec8727;
}

/* .item{
    margin-left: 2rem;
    
} */
.wrapper_right {
  margin-top: 3rem;
  object-fit: cover;
}

.item h3 {
  font-family: 'Space Grotesk';
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: #17161a;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.item .psg {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #232226;
  text-align: justify;
  text-transform: lowercase;
}

.home-lastpic {
  width: 100%;
  padding: 0;
  margin: 2rem 0;
  margin-left: -100px;
}

.landing5 {
  background: #f4f6f2;
  padding: 100px;
  padding-top: 180px;
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.client-title {
  padding-top: 4rem;
}

.client-title p {
  font-family: 'Basier Circle';
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #010a44;
  padding-bottom: 1rem;
  opacity: 0.65;
}

.client-title h3 {
  font-family: 'Space Grotesk';
  font-weight: 700;
  font-size: 40px;
  line-height: 108%;
  letter-spacing: -0.01em;
  color: #010a44;
}

.client-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  /* gap: 2rem; */

  mix-blend-mode: multiply;
  /* padding-right: 0; */
}

.landing6 {
  background: url(/src/Assets/WebImages/Vector3.svg) no-repeat,
    url(/src/Assets/WebImages/Vector4.png) no-repeat;

  background-image: url(/src/Assets/WebImages/Vector3.svg),
    url(/src/Assets/WebImages/Vector4.png);
  background-repeat: no-repeat, no-repeat;
  background-position-y: 100px, -18rem;
  background-position-x: 50px, right;

  padding: 100px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10rem;
}

.home-ph-image {
  margin-top: 11rem;
  margin-left: 6rem;
  width: 454.2px;
  height: 537.51px;
}

.ph-text {
  margin-top: 8rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.ph-text h5 {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #232226;
  text-align: left;
  margin-bottom: 1rem;
}

.ph-text h1 {
  font-family: 'Space Grotesk';
  font-weight: 700;
  font-size: 60px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #17161a;
  text-align: left;
  margin-bottom: 2rem;
}

.ph-text p {
  font-weight: 400;
  font-size: 16px;
  line-height: 32.1px;
  letter-spacing: -0.02em;
  padding-right: 3rem;
  /* width: 353px; */
  color: #212121;
}

.play-btn {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  margin-top: 0;
  padding-left: 0;
  padding-top: 0;
  /* width: 100%;
    width: 441px; */
}

.play-btn-img {
  /* width: 48%; */
  width: 180px;
}

.landing7 {
  padding: 100px;
  margin: 0;
  height: 478px;
  background-image: url(/src/Assets/WebImages/home5.png);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
}

.landing7 .pre-head {
  font-family: 'Basier Circle';
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #010a44;
  opacity: 0.65;
}

.landing7 .head {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 50px;

  letter-spacing: -0.03em;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #6cb044;
}

.landing7 .get-in {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 50px;
  /* margin-top: -8px; */
  margin-bottom: 20px;
  letter-spacing: -0.03em;
  padding: 0;

  color: #010a44;
}

.contact-btn {
  width: 152px;
  height: 50px;
  background: #6cb044;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-family: 'Space Grotesk';
  font-weight: 700;
  font-size: 16.8px;
  line-height: 22px;
  cursor: pointer;
  margin-top: 10px;
}

.contact-btn img {
  width: 15px;
  height: 13px;
}

.contact a {
  margin-bottom: 10px;
}
/* About us page */

.about1 {
  width: 100%;
  height: 400px;
}

.about-page1 {
  padding: 50px 100px;
  background: #ffffff;
}

.about-title {
  text-align: center;
  font-family: 'Space Grotesk';
  font-weight: 700;
  font-size: 36px;
  line-height: 47px;
  letter-spacing: -0.02em;
  color: #212121;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.about-body {
  display: flex;
  flex-direction: column;
  /* gap: 2rem;   */
}

.about-body > p {
  font-weight: 400;
  line-height: 200%;
  text-align: justify;
  color: #212121;
  padding: 50px;
  padding-bottom: 0;
}

.about-title1 {
  font-family: 'Space Grotesk';
  font-weight: 700;
  font-size: 36px;
  line-height: 47px;
  letter-spacing: -0.02em;
  color: #212121;
  margin-top: 5rem;
  margin-bottom: 2rem;
}

.his-chart {
  /* width: 1231px;
    height: 240.27px;
    padding:100px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 3rem;
}

.his-line {
  width: 136px;
  height: 4px;
  margin-top: -5rem;
}

.his-icon-text h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  /* width: 135.34px; */
  text-align: center;
  letter-spacing: -0.01em;
  color: #010a44;
}

.his-icon-text p {
  font-family: 'Cairo';
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #363848;
  text-align: center;
}

.about-page2 {
  padding: 50px 100px;
  background: #ffffff;
}

.about-box {
  background: #f5f5f5;
  font-style: italic;
  padding: 2rem;
}

.about-box > p {
  border-left: 5px solid #6cb044;
  padding-left: 1rem;
  font-size: 24px;
  line-height: 37px;
  color: #7a7a7a;
  font-size: 24px;
}

.our p {
  font-size: 16px;
  line-height: 32px;
  padding: 50px 30px;
  text-align: justify-all;
  margin-bottom: 80px;
}
.box .square .icon-img1 {
  width: 4rem;
  margin: 0.2rem 0.2rem;
}

.box .square .icon-img2 {
  width: 3.2rem;
  margin: 0.7rem 0.8rem;
}
.gallery-container {
  margin-top: 50px;
}
.indicator {
  cursor: pointer;
}
.our h2 {
  text-align: center;
  font-family: 'Space Grotesk';
  font-weight: 700;
  font-size: 36px;
  line-height: 47px;
  letter-spacing: -0.02em;
  color: #212121;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.about-para p {
  padding: 3rem 6rem;
  font-weight: 400;
  line-height: 32px;
  color: #212121;
  text-align: justify;
}

.about-page3 {
  padding: 100px;
  padding-top: 0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
}

.about-text {
  /* width: 579px;
    height: 394px; */
}

.column-left {
  /* padding-right: 24px; */
}

.column-right {
  /* padding-left: 24px; */
}

.about-text .up-line {
  background-color: #11294c;
  width: 58px;
  height: 6px;
  border-radius: 100px;
  margin-top: 0;
  margin-bottom: 0;
}

.about-text h1 {
  text-align: left;
  margin: 3rem auto;
  margin-top: 1rem;
  font-family: 'Space Grotesk';
  font-weight: 700;
  font-size: 36px;
  line-height: 47px;
  letter-spacing: -0.02em;
  color: #212121;
}

.about-text p {
  font-weight: 400;
  line-height: 32px;
  color: #212121;
  text-align: justify;
  /* width: 567px; */
}

.about-pics {
  /* width: 579px;
height: 394px; */
  width: 100%;
  object-fit: cover;
}

/* .about-pics img{
    width: 100%;
    object-fit: cover;
} */

.about-page5 {
  padding: 100px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 5rem;
  margin-bottom: 0;
  background: #d9d9d9;
  /*opacity: 0.2; */
}

.arrow-round {
  height: 54px;
  width: 54px;
  border-radius: 50%;
  border: 1px solid #ebe5db;
  background: #ebe5db;
  /* Shadow-01 */

  box-shadow: 0px 23px 49px rgba(0, 0, 0, 0.04);
  border-radius: 40px;
  filter: drop-shadow(0px 23px 49px rgba(0, 0, 0, 0.04));
  border-radius: 40px;
}

.arrow {
  padding: 15px;
}

.testimonal {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.testimonal-visible {
  display: block;
  animation: fadeVisibility 0.5s;
}

.testimonal-hidden {
  display: none;
}

.testi-quote {
  margin-left: 0;

  padding-right: 90%;
}

.testi-body {
  padding: 3rem;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #212121;
}

.testi-center {
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  gap: 1rem;
  align-items: center;
}

.testi-desi {
  align-items: left;
}

.testi-rate {
  align-items: right;
}

.testi-name {
  font-family: 'Space Grotesk';
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #212121;
}

.testi-sub {
  color: #3d3d3d;
}

.about-page6 {
  background: url(/src/Assets/WebImages/Vector3.svg) no-repeat,
    url(/src/Assets/WebImages/Vector4.png) no-repeat;

  background: #ffffff;
  /* background-image: url(/src/Assets/WebImages/Vector5.svg), url(/src/Assets/WebImages/Vector6.png); */
  background-image: url(/src/Assets/WebImages/Vector3.svg),
    url(/src/Assets/WebImages/Vector4.png);
  background-repeat: no-repeat, no-repeat;
  background-position-y: 100px, 825px;
  background-position-x: 200px, 100px;
  padding: 100px;
  display: flex;
  justify-content: space-between;
  gap: 5rem;
  padding-left: 20rem;
}

.about-ph-image {
  object-fit: contain;
  padding-left: 1.75rem;
  padding-top: 11.5rem;
}

.about-ph-text {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 10rem;
}

.about-ph-text h5 {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #232226;
  text-align: left;
  margin-bottom: 1rem;
}

.about-ph-text h1 {
  font-family: 'Space Grotesk';
  font-weight: 700;
  font-size: 60px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #17161a;
  text-align: left;
  margin-bottom: 2rem;
}

.about-ph-text p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #232226;
  margin-bottom: 0;
  /* width: 353px; */
}

.about-list {
  display: flex;
  justify-content: space-between;
  padding: 7rem 10rem;
}

.round {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}

.round p {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #010a44;
}

/* Other Services Page */

.other-pic {
  width: 100%;
  height: 400px;
  margin: 0;
}

.other-page1 {
  background: #ffffff;
  background-image: url(/src/Assets/WebImages/services-bg1.svg),
    url(/src/Assets/WebImages/services-bg2.png);
  /* background-position: right, bottom; */
  background-repeat: no-repeat, no-repeat;
  background-position-y: 400px, 1450px;
  background-position-x: 920px, 800px;
  margin: 0;
  padding: 50px 100px;
}

.side-head a {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  cursor: pointer;
}

.side-head a:hover {
  font-weight: 700;
}

.side-head .current-view {
  font-weight: 700;
}

.current-view {
  cursor: default;
}

h1 {
  font-family: 'Space Grotesk';
  font-weight: 700;
  font-size: 48px;
  line-height: 63px;
  letter-spacing: -0.02em;
  color: var(--darkblack);
  text-align: center;
  margin-bottom: 8rem;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  padding-top: 0;
  align-items: center;
  margin-right: 0;
  margin-top: 0;
}

.box {
  height: 36rem;
  border: 1px solid #dfdfdf;
  padding: 2rem;
  margin-top: 0;
  border-radius: 10px;

  background: #ffffff;
}

.box .square {
  width: 73px;
  height: 73px;
  background: rgba(108, 176, 68, 0.2);
  border-radius: 50%;
  position: relative;
}

.box .square .icon-img {
  position: absolute;
  padding: 1rem 1rem 1rem 1.2rem;
}

.box h2 {
  font-family: 'Space Grotesk';

  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.03em;
  color: #1b1c31;

  margin-top: 1rem;
}

.box p {
  font-weight: 500;
  font-size: 12.9px;
  line-height: 25px;
  letter-spacing: -0.02em;
  color: #64607d;
  margin-top: 1rem;
  text-align: justify;
}

.other-page2 {
  background: #f4f6f2;
  background-image: url(/src/Assets/WebImages/services-bg3.png);
  background-repeat: no-repeat;
  background-position-y: 400px;
  background-position-x: -90px;
  padding: 100px 0;
}

.title {
  font-family: 'Space Grotesk';
  font-weight: 700;
  font-size: 48px;
  line-height: 63px;
  text-align: center;
  letter-spacing: -0.02em;

  color: var(--darkblack);
}

.table-box {
  /* margin: 2rem 30%; */
  margin-left: 28vw;
  margin-right: 28vw;
  margin-top: 4rem;
  /* width: 717px; */
  width: 44vw;
  height: 726px;
  display: grid;

  grid-template-columns: 1fr;

  color: #223345;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 18px;
  background-color: #ffffff;
  border: 1px solid #f2f2f2;
  text-align: center;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.12));
  border-radius: 8px;
}

.table-box tr {
  padding: 24px;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #223345;
}

td:nth-child(1) {
  flex: 1;
  text-align: left;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #223345;
}

td:nth-child(2) {
  justify-self: right;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #223345;
}

/* Gallery Page */

.gallery-pic {
  width: 100%;
  height: 400px;
}

.gallery-page {
  background: #ffffff;
  padding: 50px 100px;
  background-image: url(/src/Assets/WebImages/gallery-bg.svg);
  background-position: right;
  background-repeat: no-repeat;
  background-position-y: -200px;
}

.gallery-container {
  /* padding: 1em; */
}

.gallery-title {
  text-align: center;
  font-family: 'Space Grotesk';
  font-weight: 700;
  font-size: 48px;
  line-height: 63px;
  letter-spacing: -0.02em;
  color: #212121;
  margin-top: 1rem;
  margin-bottom: 4rem;
}

.indicator {
  display: flex;
  justify-content: space-between;
  padding: 0;
  border-bottom: 1px solid #ebe5db;
}

.indicator li {
  list-style: none;
}

.indicator li {
  font-weight: 400;
  font-size: 18px;
  text-decoration: none;
  line-height: 23px;
  color: #666464;
}

.select-gallery ul li:hover,
.select-gallery ul li:focus {
  font-weight: 700;
}

.indicator .active {
  font-weight: 700;
  padding-bottom: 2rem;
  border-bottom: 5px solid #f18241;
}

.pictures {
  padding-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.gallery-pictures {
  background: #ffffff;
  /* width: 379.59px;
  height: 347px; */

  border: 1px solid #ebe5db;
  border-radius: 20px;
}

.gallery-pictures img {
  object-fit: cover;
  width: 100%;
}

.pic-title {
  font-family: 'Space Grotesk';
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #0d0e0c;
  margin: 2rem auto;
  margin-top: 1.5rem;
}

/* Contact Page */
.contact-pic {
  width: 100%;
  height: 400px;
}

.contact-page1 {
  background: #ffffff;
  padding: 50px 100px;
  background-image: url(/src/Assets/WebImages/contact-bg.svg);
  background-position: right;
  background-repeat: no-repeat;
  background-position-y: -220px;
}

.contact-title {
  font-family: 'Space Grotesk';
  font-weight: 700;
  font-size: 48px;
  line-height: 63px;
  letter-spacing: -0.02em;
  color: #212121;
  margin-bottom: 1rem;
}

.contact-subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #808080;
}

.contact-container {
  margin: 5rem;
  width: 90%;

  background: #ffffff;
  padding: 1rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 15px;
}

form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: 3rem 7rem;
}

form .msg {
  grid-column: span 2;
}

form .input-box .details {
  display: block;
  color: #5a524c;
  font-weight: 400;
  font-size: 13.5134px;
  line-height: 24px;
  margin-bottom: 1rem;
}

input {
  outline: none;
  border: none;
  border-bottom: 1.5px solid #ebe5db;
  border-radius: 4.50445px;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  color: #0d0e0c;
  text-decoration: none;
  margin-bottom: 2rem;
}

form .button {
  grid-column: span 2;
  justify-self: flex-end;
  height: 52px;
  width: 212px;
  justify-self: flex-end;
}

form .button input {
  cursor: pointer;
  height: 100%;
  width: 100%;
  border-radius: 71.3205px;
  background: #6cb044;
  border: none;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 21px;
  font-size: 16px;
  color: #ffffff;
}

form .button input:hover {
  opacity: 0.8;
}

.map {
  width: 100%;
  height: 600px;
  position: relative;
  margin-bottom: 0;
}
.map-container {
  position: relative;
  padding: 10px;
  border-radius: 10px;
}
.map-address-container {
  position: absolute;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 21.3439px 45.4718px rgba(0, 0, 0, 0.04);
  padding: 40px;
  width: 460px;
  height: 420px;
  right: 8rem;
  margin-top: -15rem;
  z-index: 10000000;
}

.contact-map {
  padding: 40px;
  position: absolute;
  margin-left: 42rem;
  margin-top: -20rem;
  width: 575px;
  height: 450px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 21.3439px 45.4718px rgba(0, 0, 0, 0.04);
}

.location-map {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.location-image {
  width: 45;
  /* height:50; */
}

.phone-map {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.phone-image {
  width: 40;
}

.phone-text {
  margin-left: 30px;
  margin-top: 12px;
  font-family: 'DM Sans';
  font-weight: 700;
}

.location-text {
  margin-left: 30px;
  font-family: 'DM Sans';
  font-weight: 700;
}

/* img {
    max-width: 100%;
    object-fit: cover;
} */
@media (max-width: 600px) {
  .login-list {
    position: relative;
    background-color: #424242;
    margin-top: 10px;
    width: 157px;
    /* height: 78px; */
    border-bottom: 2px solid #6cb044;
    /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
    /* z-index: 1; */
  }

  .navbar {
    display: none;
  }

  header {
    display: block;
  }

  header {
    display: flex;
    justify-content: space-between;
    padding: 1em;
  }

  .logo img {
    /* width: 100%; */
    width: 186.38px;
    height: 43px;
  }

  .nav {
    position: fixed;
    background-color: #ffffff;
    text-decoration: none;
    color: #010a44;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    transform: translateX(100%);
    transition: transform 250ms cubic-bezier(0.5, 0, 0.5, 1);
  }

  .nav__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .nav__link {
    color: inherit;
    font-weight: bold;
    font-size: 1em;
    text-decoration: none;
  }

  .nav__link:hover {
    font-size: 1.5em;
    border-bottom: 3px solid #6cb044;
  }

  .nav-toggle {
    padding: 0.5em;
    background: transparent;
    border: 0;
    cursor: pointer;
    position: absolute;
    right: 1em;
    top: 1em;
    z-index: 1000;
  }

  .nav-open .nav {
    transform: translateX(0);
  }

  .nav-open .nav-toggle {
    position: fixed;
  }

  .nav-open .hamburger {
    transform: rotate(0.625turn);
  }

  .nav-open .hamburger::before {
    transform: rotate(90deg) translateX(-6px);
  }

  .nav-open .hamburger::after {
    opacity: 0;
  }

  .hamburger {
    display: block;
    position: relative;
  }

  .hamburger,
  .hamburger::before,
  .hamburger::after {
    background: #11294c;
    width: 2em;
    height: 3px;
    border-radius: 1em;
    transition: transform 250ms ease-in-out;
  }

  .hamburger::before,
  .hamburger::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
  }

  .hamburger::before {
    top: 6px;
  }

  .hamburger::after {
    bottom: 6px;
  }

  .login-list {
    /* top: -0.5rem;
 left: 8rem; */
  }

  .active {
    display: block;
  }

  .landing1,
  .landing2 {
    padding: 2rem;
    display: flex;
    flex-direction: column;
  }

  .landing2 {
    flex-direction: column;
    gap: 1rem;
  }

  .landing3,
  .landing4,
  .landing5 {
    padding: 1rem;
  }

  .landing1 {
    margin-top: 1rem;
    gap: 1rem;
    padding-bottom: 5rem;
  }

  .home-pic1 {
    width: 100%;
    position: relative;
  }

  .home-pic2 {
    width: 60%;
    position: absolute;
    margin-top: 40rem;
  }

  .home-why {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .landing4 {
    padding-top: 5rem;
    flex-direction: column-reverse;
    gap: 1rem;
  }

  .landing5 {
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;
  }

  .client-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 1rem;
  }

  .landing6 {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 1rem;
  }

  .play-btn {
    flex-direction: column;
    gap: 0;
  }

  footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
  }

  .symbols {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }

  .copyright {
    flex-direction: column;
  }

  .about-page1,
  .about-page2,
  .about-page4,
  .about-page5,
  .about-page6 {
    padding: 2rem;
  }

  .about-para {
    padding: 2rem;
  }

  .about-page3 {
    padding: 1rem;
    padding-left: 2rem;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .row {
    display: flex;
    flex-direction: column;
  }

  .about-pics {
    order: 1;
    width: 90%;
    margin-bottom: 1rem;
  }

  .about-text p {
    width: 90%;
  }

  .about-text {
    margin-top: -2rem;
  }

  .about-page5 .testi {
    width: 20%;
  }

  .about-page5 {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }

  .about-page6 {
    flex-direction: column;
  }

  .other-page1,
  .other-page2 {
    padding: 1rem;
  }

  .side-head {
    margin-bottom: 2rem;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .table-box {
    align-items: center;
    margin-left: 1rem;
    width: 95%;
  }

  .gallery-page {
    padding: 1rem;
  }

  .indicator {
    display: flex;
    flex-direction: column;
  }

  .indicator li {
    padding: 0.5rem;
  }

  .indicator li .active {
    border-bottom: none;
  }

  .pictures {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-page1 {
    padding: 1rem;
  }

  .contact-container {
    margin-left: 1rem;

    width: 95%;
  }

  .contact-page2 {
    width: 100%;
  }

  .contact-map {
    width: 70%;
    height: 70%;
    margin-left: -28rem;
    margin-top: 35rem;
    padding-bottom: 10rem;
  }

  .round img {
    width: 35%;
    height: 35%;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  form .input-box {
    width: 100%;
  }

  .testi-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  .testimonal {
    padding: 0;
  }
}

.backfill {
  position: absolute;
  width: 100vw;
  height: 892px;
  /* right: -1440px; */
  top: 0px;

  background: linear-gradient(20.1deg, #70b245 8.53%, #ffffff 81.94%);
  opacity: 0.2;
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
}
